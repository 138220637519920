import { HeaderTypes, HttpClientHelper, PTVNClient } from '@ptvn-react/authentication';
import { CountryCode } from '@utils/constants/CountryConstant';
import { TimerEnum } from '@utils/enum/CommonEnum';
import { AxiosRequestConfig } from 'axios';
import { Observable, timer } from 'rxjs';
import { delayWhen, map, retryWhen, take, timeout } from 'rxjs/operators';
import APIConstant from '../utils/constants/APIConstant';
import { AddNewBranchRequest } from './request/AddNewBranchRequest';
import { ContactAddressRequest } from './request/AddressRequest';
import { AvailabilityRequest } from './request/AvailabilityRequest';
import { BuyerListRequest, CheckStatusAddInvitationCodeRequest, ValidateAddInvitationCodeRequest } from './request/BuyerRequest';
import { CancelRequestToJoinRequest } from './request/CancelRequestTojoinRequest';
import { CheckUpdateStatusResponse, CompanyDetailRequest, HistoryRequest, HistoryResponse, UpdateAddressRequest, UpdateBusinessInformationRequest, UpdateDocumentsInformationRequest, UpdateIntroductionRequest } from './request/CompanyProfile';
import { ConsentRequest } from './request/ConsentRequest';
import { QuickRegisterRequest } from './request/QuickRegisterRequest';
import { RegisterServiceRequest } from './request/RegisterServiceRequest';
import { RequestToJoinRequest } from './request/RequestToJoinRequest';
import { UserBranchDetailRequest, UserBranchRequest } from './request/UserBranchRequest';
import { UserDetailRequest } from './request/UserDetailRequest';
import { VerifyUserSWWRequest } from './request/VerifyUserSWWRequest';
import { ContactAddressResponse } from './response/AddressResponse';
import { AvailabilityResponse } from './response/AvailabilityResponse';
import { AvailableServiceResponse } from './response/AvailableServiceResponse';
import { PTVNResponse } from './response/BaseResponse';
import { BuyerListResponse } from './response/BuyerListResponse';
import {
  BranchesResponse,
  BusinessEntityType,
  CompaniesResponse, CompanyInvitationCodeResponse, CompanyInvitationCodeResponse2, CompanyServiceResponse, JobTitleResponse, QuickRegisterResponse, RequestStatusAndServiceStatusResponse, RequestToJoinResponse, CompanyDetailResponse, CompanyDocumentTypeResponse, RegisterStatusResponse, FirstAccessRedirectResponse, PopupMessageResponse, AutoAcceptPOShowNotificationResponse, AutoAcceptPODetailResponse, AutoAcceptPOPurchaser, AutoAcceptPOHistoryResponse, MasterPaymentTerm, UserAdminPOListResponse, UserAdminPOResponse
} from './response/CompanyResponse';
import { ConsentAcceptResponse, ConsentResponse, GlobalSSAResponse } from './response/ConsentResponse';
import { UserBranchDetailResponse, UserBranchResponse } from './response/UserBranchResponse';
import { JobTitleId } from '@utils/constants/CompanyConstant';
import { MasterLocaleResponse } from './response/MasterLocaleResponse';
import { MasterTimeZoneResponse } from './response/MasterTimeZoneResponse';
import { UpdatePreferenceRequest } from './request/UpdatePreferenceRequest';
import { UserSWWInformationResponse } from './response/UserSWWInformationResponse';
import { MasterFieldApproveResponse } from './response/MasterFieldApproveResponse';
import { AutoAcceptPOActiveRequest, UpdateAutoAcceptPOStatusRequest } from './request/AutoAcceptPORequest';
import { AutoAcceptPOStatusType } from '@utils/constants/AutoAcceptPOConstants';
import { CheckShowAutoAcceptPOMenu } from './response/CheckShowMenuAutoAcceptPOResponse';

export class CompanyClient {
  public static checkAvailability(
    requestBody: AvailabilityRequest
  ): Observable<AvailabilityResponse> {
    const url = APIConstant.company.checkAvailability;
    const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(
      HeaderTypes.EXCLUDE_TOKEN
    );

    return PTVNClient.post<PTVNResponse<AvailabilityResponse>>(url, requestBody, options).pipe(
      timeout(TimerEnum.THIRTY_SECONDS),
      map((m) => m.data.data)
    );
  }

  public static getBusinessEntityType(): Observable<BusinessEntityType[]> {
    const url = APIConstant.company.getBusinessEntityType;
    const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(
      HeaderTypes.EXCLUDE_TOKEN
    );

    return PTVNClient.get<PTVNResponse<BusinessEntityType[]>>(url, options).pipe(
      map((m) => m.data.data)
    );
  }

  public static getCompany(): Observable<CompaniesResponse> {
    const url = `${APIConstant.company.getMyCompanies}`;
    const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(HeaderTypes.SSO_TOKEN);
    return PTVNClient.get<PTVNResponse<CompaniesResponse>>(url, options).pipe(
      map((m) => m.data.data)
    );
  }

  public static getRegisterStatus(branchId: number): Observable<RegisterStatusResponse> {
    const url = `${APIConstant.branch.registerStatus}${branchId}`;
    const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(HeaderTypes.SSO_TOKEN);
    return PTVNClient.get<PTVNResponse<RegisterStatusResponse>>(url, options).pipe(
      map((m) => m.data.data)
    );
  }

  public static verifyUserSWW(companyID: number, branchNumber: string, branchId: number, username: string,
    checkAcceptCOC?: boolean): Observable<PTVNResponse> {
    const url = `${APIConstant.company.verifyUserSWW}`;

    const request: VerifyUserSWWRequest = {
      companyId: companyID,
      branchNumber: branchNumber,
      branchId: branchId,
      username: username ? username : null,
      checkAcceptCOC: checkAcceptCOC
    };
    const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(HeaderTypes.SSO_TOKEN);
    let countRound: number = 0;
    let delayTime: number = 0;
    return PTVNClient.post<PTVNResponse>(url, request, options).pipe(
      map((m) => m.data),
      retryWhen((errors) =>
        errors.pipe(
          delayWhen((_, i) => {
            countRound = countRound + 1;
            if (countRound == 1) {
              delayTime = TimerEnum.ONE_MINUTE;
            } else if (countRound == 2) {
              delayTime = TimerEnum.ONE_MINUTE;
            } else if (countRound == 3) {
              delayTime = TimerEnum.THREE_MINUTE;
            }

            return timer(delayTime);
          }),
          take(4)
        )
      )
    );
  }

  public static verifyUserSWWNoRetry(companyID: number, branchNumber: string, branchId: number, username: string,
    checkAcceptCOC?: boolean): Observable<PTVNResponse> {
    const url = `${APIConstant.company.verifyUserSWW}`;

    const request: VerifyUserSWWRequest = {
      companyId: companyID,
      branchNumber: branchNumber,
      branchId: branchId,
      username: username ? username : null,
      checkAcceptCOC: checkAcceptCOC
    };
    const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(HeaderTypes.SSO_TOKEN);
    let countRound: number = 0;
    let delayTime: number = 0;
    return PTVNClient.post<PTVNResponse>(url, request, options).pipe(
      map((m) => m.data)
    );
  }

  public static quickRegister(
    requestBody: QuickRegisterRequest
  ): Observable<QuickRegisterResponse> {
    const url = `${APIConstant.company.quickRegister}`;
    const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(HeaderTypes.SSO_TOKEN);
    return PTVNClient.post<PTVNResponse<QuickRegisterResponse>>(url, requestBody, options).pipe(
      map((m) => m.data.data)
    );
  }

  public static getConsent(): Observable<PTVNResponse<ConsentResponse>> {
    const url = `${APIConstant.consent.getConsent}`;
    const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(
      HeaderTypes.EXCLUDE_TOKEN
    );
    return PTVNClient.get<PTVNResponse<ConsentResponse>>(url, options).pipe(map((m) => m.data));
  }

  public static getRequestConsent(): Observable<PTVNResponse<ConsentResponse>> {
    const url = `${APIConstant.consent.getRequestConsent}`;
    const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(HeaderTypes.SSO_TOKEN);
    return PTVNClient.get<PTVNResponse<ConsentResponse>>(url, options).pipe(map((m) => m.data));
  }

  public static acceptConsent(consentId: string): Observable<PTVNResponse<ConsentAcceptResponse>> {
    const url = `${APIConstant.consent.acceptConsent}`;
    const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(HeaderTypes.SSO_TOKEN);
    const requestBody: ConsentRequest = {
      consentId: consentId,
    };
    return PTVNClient.post<PTVNResponse<ConsentAcceptResponse>>(url, requestBody, options).pipe(
      map((m) => m.data)
    );
  }

  public static getCOC(typeCOC: string, username?: string): Observable<PTVNResponse<ConsentResponse>> {
    let url = typeCOC == 'CP' ? `${APIConstant.consent.getCOCCP}` :
      `${APIConstant.consent.getCOCTrue}?username=${username}`;
    const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(HeaderTypes.SSO_TOKEN);
    return PTVNClient.get<PTVNResponse<ConsentResponse>>(url, options).pipe(map((m) => m.data));
  }

  public static acceptCOC(
    typeCOC: string,
    consentId: string,
    username?: string
  ): Observable<PTVNResponse<ConsentAcceptResponse>> {
    const url =
      typeCOC == 'CP'
        ? `${APIConstant.consent.acceptCOCCP}`
        : `${APIConstant.consent.acceptCOCTrue}`;
    const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(HeaderTypes.SSO_TOKEN);
    const requestBody: ConsentRequest = {
      consentId: consentId,
      username: username
    };
    return PTVNClient.post<PTVNResponse<ConsentAcceptResponse>>(url, requestBody, options).pipe(
      map((m) => m.data)
    );
  }

  public static getCOCByApplicationName(applicationName: string, username?: string, companyId?: number): Observable<PTVNResponse<ConsentResponse>> {
    let url = `${APIConstant.consent.getCOC}/${applicationName}`
    if (username) {
      url += `?username=${username}`;
    }

    if (companyId) {
      if (url.includes('?')) {
        url += `&companyId=${companyId}`
      } else {
        url += `?companyId=${companyId}`
      }
    }

    const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(HeaderTypes.SSO_TOKEN);
    return PTVNClient.get<PTVNResponse<ConsentResponse>>(url, options).pipe(map((m) => m.data));
  }

  public static getCOCByApplicationNameByCompanyId(applicationName: string): Observable<PTVNResponse<ConsentResponse>> {
    let url = APIConstant.consent.getCOCByCompany.replace('{applicationName}', applicationName)

    const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(HeaderTypes.SSO_TOKEN);
    return PTVNClient.get<PTVNResponse<ConsentResponse>>(url, options).pipe(map((m) => m.data));
  }

  public static getGlobalSSA(): Observable<PTVNResponse<GlobalSSAResponse>> {
    let url = APIConstant.consent.globalSSA;

    const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(HeaderTypes.SSO_TOKEN);
    return PTVNClient.get<PTVNResponse<GlobalSSAResponse>>(url, options).pipe(map((m) => m.data));
  }

  public static acceptCOCByApplicationName(
    applicationName: string,
    consentId?: string,
    username?: string
  ): Observable<PTVNResponse<ConsentAcceptResponse>> {
    const url = `${APIConstant.consent.acceptCOC}/${applicationName}`;
    const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(HeaderTypes.SSO_TOKEN);
    let requestBody: ConsentRequest = {
      consentId: consentId!
    };

    if (username) {
      requestBody = { ...requestBody, username: username }
    }

    return PTVNClient.post<PTVNResponse<ConsentAcceptResponse>>(url, requestBody, options).pipe(
      map((m) => m.data)
    );
  }

  public static getBranches(
    taxId: string,
    countryCode: string,
    branchStatusId?: number
  ): Observable<PTVNResponse<BranchesResponse>> {
    const url = APIConstant.company.getBranches
      .replace('{taxId}', taxId)
      .replace('{countryCode}', countryCode)
      .replace('{branchStatusId}', branchStatusId ? branchStatusId.toString() : '');
    const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(HeaderTypes.SSO_TOKEN);

    return PTVNClient.get<PTVNResponse<BranchesResponse>>(url, options).pipe(map((m) => m.data));
  }

  public static requestToJoin(
    requestBody: RequestToJoinRequest
  ): Observable<RequestToJoinResponse> {
    const url = APIConstant.company.requestToJoin;
    const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(HeaderTypes.SSO_TOKEN);

    return PTVNClient.post<PTVNResponse<RequestToJoinResponse>>(url, requestBody, options).pipe(
      map((m) => m.data.data)
    );
  }

  public static addNewBranch(requestBody: AddNewBranchRequest): Observable<QuickRegisterResponse> {
    const url = APIConstant.company.addNewBranch;
    const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(HeaderTypes.SSO_TOKEN);

    return PTVNClient.post<PTVNResponse<QuickRegisterResponse>>(url, requestBody, options).pipe(
      map((m) => m.data.data)
    );
  }

  public static getUserBranch(
    request: UserBranchRequest
  ): Observable<PTVNResponse<UserBranchResponse>> {
    const url = APIConstant.company.getUserBranch
      .replace('{companyId}', request.companyId)
      .replace('{branchNumber}', request.branchNumber);
    const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(HeaderTypes.SSO_TOKEN);

    let countRound: number = 0;
    let delayTime: number = 0;
    return PTVNClient.get<PTVNResponse<UserBranchResponse>>(url, options).pipe(
      map((m) => m.data),
      retryWhen((m) =>
        m.pipe(
          delayWhen((_, i) => {
            countRound = countRound + 1;
            if (countRound == 1) {
              delayTime = TimerEnum.ONE_MINUTE;
            } else if (countRound == 2) {
              delayTime = TimerEnum.TWO_MINUTE;
            } else if (countRound == 3) {
              delayTime = TimerEnum.FIVE_MINUTE;
            }

            return timer(delayTime);
          }),
          take(4)
        )
      )
    );
  }

  public static cancelRequestToJoin(
    requestBody: CancelRequestToJoinRequest
  ): Observable<CancelRequestToJoinRequest> {
    const url = APIConstant.branch.cancelRequestToJoin;
    const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(HeaderTypes.SSO_TOKEN);

    return PTVNClient.put<PTVNResponse<CancelRequestToJoinRequest>>(url, requestBody, options).pipe(
      map((m) => m.data.data)
    );
  }

  public static companyServices(
    taxId: string,
    branchNumber: string,
    countryCode: string
  ): Observable<PTVNResponse<CompanyServiceResponse>> {
    const url = APIConstant.company.companyServices;
    const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(HeaderTypes.SSO_TOKEN);
    const requestBody = {
      taxID: taxId,
      branchNumber: branchNumber,
      countryCode: countryCode,
    };

    return PTVNClient.post<PTVNResponse<CompanyServiceResponse>>(url, requestBody, options).pipe(
      map((m) => m.data)
    );
  }

  public static checkCompanyInvitationCode(inviteCode: string, branchId?: number): Observable<PTVNResponse<CompanyInvitationCodeResponse>> {
    const inviteCodeFomat = inviteCode?.replace("‘", "'").replace("’", "'").replace("‛", "'").replace("‵", "'").replace("′", "'").replace("`", "'")! || '';
    const url = `${APIConstant.company.checkCompanyInvitationCode}?code=${inviteCodeFomat}` + (branchId ? `&branchId=${branchId}` : '');
    const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(HeaderTypes.SSO_TOKEN);

    return PTVNClient.get<PTVNResponse<CompanyInvitationCodeResponse>>(url, options).pipe(map((m) => m.data));
  }

  public static validateAddCompanyInvitationCode(request: ValidateAddInvitationCodeRequest): Observable<PTVNResponse<CompanyInvitationCodeResponse2>> {
    const url = APIConstant.company.validateAddCompanyInvitationCode;
    const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(HeaderTypes.SSO_TOKEN);
    request = {
      ...request,
      invitationCode: request.invitationCode?.replace("‘", "'").replace("’", "'").replace("‛", "'").replace("‵", "'").replace("′", "'").replace("`", "'")! || ''
    }

    return PTVNClient.post<PTVNResponse<CompanyInvitationCodeResponse2>>(
      url,
      request,
      options
    ).pipe(
      map((m) => m.data)
    );
  }

  public static updateUserDetail(requestBody: UserDetailRequest) {
    const url = `${APIConstant.company.updateUserDetail}`;
    const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(
      HeaderTypes.SSO_TOKEN
    );
    return PTVNClient.post<PTVNResponse<VoidFunction>>(
      url,
      requestBody,
      options
    );
  }

  public static getJobTitle(): Observable<JobTitleResponse[]> {
    const url = `${APIConstant.company.getJobTitle}`;
    const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(
      HeaderTypes.SSO_TOKEN
    );
    return PTVNClient.get<PTVNResponse<JobTitleResponse[]>>(
      url,
      options
    ).pipe(map((m) => m.data.data));
  }

  public static registerService(request: RegisterServiceRequest.Request) {
    const url = `${APIConstant.company.registerService}`;
    const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(
      HeaderTypes.SSO_TOKEN
    );
    return PTVNClient.post<PTVNResponse<VoidFunction>>(
      url,
      request,
      options
    );
  }

  public static updateCompanyIntroduction(request: UpdateIntroductionRequest) {
    const url = `${APIConstant.company.updateIntroduction}`;
    const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(
      HeaderTypes.SSO_TOKEN
    );
    return PTVNClient.put<PTVNResponse<VoidFunction>>(
      url,
      request,
      options
    );
  }

  public static updateCompanyAddress(request: UpdateAddressRequest) {
    const url = `${APIConstant.company.updateAddress}`;
    const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(
      HeaderTypes.SSO_TOKEN
    );
    return PTVNClient.put<PTVNResponse<VoidFunction>>(
      url,
      request,
      options
    );
  }

  public static checkUpdateRequestCompanyStatus(companyId: number, branchId: number): Observable<CheckUpdateStatusResponse> {
    const url = APIConstant.company.checkUpdateCompanyStatus
      .replace('{companyId}', companyId.toString())
      .replace('{branchId}', branchId.toString());
    const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(HeaderTypes.SSO_TOKEN);

    return PTVNClient.get<PTVNResponse<CheckUpdateStatusResponse>>(url, options).pipe(map((m) => m.data.data));
  }

  public static updateBusinessInformation(request: UpdateBusinessInformationRequest) {
    const url = APIConstant.company.updateBusinessInformation
    const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(
      HeaderTypes.SSO_TOKEN
    );
    return PTVNClient.put<PTVNResponse<VoidFunction>>(
      url,
      request,
      options
    );
  }

  public static updateDocumentsInformation(request: UpdateDocumentsInformationRequest) {
    const url = APIConstant.company.updateDocumentsInformation;
    const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(
      HeaderTypes.SSO_TOKEN
    );
    return PTVNClient.put<PTVNResponse<VoidFunction>>(
      url,
      request,
      options
    );
  }

  public static getHistory(request: HistoryRequest): Observable<HistoryResponse> {
    const url = APIConstant.company.getHistory
    const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(
      HeaderTypes.SSO_TOKEN
    );
    return PTVNClient.post<PTVNResponse<HistoryResponse>>(url, request, options).pipe(
      map((m) => m.data.data)
    );
  }

  public static getBuyerList(request: BuyerListRequest): Observable<BuyerListResponse> {
    const url = APIConstant.company.getBuyerList
    const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(
      HeaderTypes.SSO_TOKEN
    );
    return PTVNClient.post<PTVNResponse<BuyerListResponse>>(url, request, options).pipe(
      map((m) => m.data.data)
    );
  }

  public static checkStatusAddInvitationCode(request: CheckStatusAddInvitationCodeRequest) {
    const url = APIConstant.company.checkStatusAddInvitationCode.replace('{branchId}', request.branchId.toString())
    const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(
      HeaderTypes.SSO_TOKEN
    );
    return PTVNClient.get<PTVNResponse<CheckUpdateStatusResponse>>(url, options).pipe(map((m) => m.data.data));
  }

  public static updateCompanyDetail(request: CompanyDetailRequest): Observable<PTVNResponse> {
    const url = APIConstant.company.updateCompanyDetail
    const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(
      HeaderTypes.SSO_TOKEN
    );
    return PTVNClient.post<PTVNResponse>(url, request, options).pipe(
      map((m) => m.data)
    );
  }

  public static getRequestStatusAndServiceStatus(branchId: number): Observable<PTVNResponse<RequestStatusAndServiceStatusResponse>> {
    const url = APIConstant.company.getRequestStatusAndServiceStatus
    const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(
      HeaderTypes.SSO_TOKEN
    );
    const requerequestBodyst = {
      branchId: branchId
    }
    return PTVNClient.post<PTVNResponse>(url, requerequestBodyst, options).pipe(
      map((m) => m.data)
    );
  }

  public static getCompanInfo(ticketCode: string): Observable<PTVNResponse<CompanyDetailResponse>> {
    const url = APIConstant.company.getCompanyInfo.replace('{ticketCode}', ticketCode);
    const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(
      HeaderTypes.EXCLUDE_TOKEN
    );
    return PTVNClient.get<PTVNResponse>(url, options).pipe(
      map((m) => m.data)
    );
  }

  public static getCompanyDocumentType(identityType: number, countryCode: string, isVatRegistration: boolean): Observable<[CompanyDocumentTypeResponse]> {
    let url = APIConstant.company.getCompanyDocumentType
      .replace('{identityType}', identityType?.toString())
      .replace('{countryCode}', (countryCode?.toUpperCase() === CountryCode.TH || countryCode?.toUpperCase() === 'THAI') ? 'Thai' : 'Inter');

    if (isVatRegistration !== undefined && isVatRegistration !== null) {
      url = url + '&isVatRegistration=' + isVatRegistration?.toString();
    }
    const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(
      HeaderTypes.SSO_TOKEN
    );
    return PTVNClient.get<PTVNResponse>(url, options).pipe(
      map((m) => m.data.data)
    );
  }

  public static updateUserContactEmail(
    email: string
  ): Observable<PTVNResponse<any>> {
    const url = APIConstant.company.updateUserContactEmail;
    const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(HeaderTypes.SSO_TOKEN);
    const requestBody = {
      email: email
    };

    return PTVNClient.put<PTVNResponse<any>>(url, requestBody, options).pipe(
      map((m) => m.data)
    );
  }

  public static getUserBranchDetail(branchId: number): Observable<PTVNResponse<UserBranchDetailResponse>> {
    const url = APIConstant.company.getUserBranchDetail.replace('{branchId}', branchId.toString());
    const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(HeaderTypes.SSO_TOKEN);
    return PTVNClient.get<PTVNResponse<UserBranchDetailResponse>>(url, options).pipe(
      map((m) => m.data)
    );
  }

  public static getUserContactAddress(): Observable<PTVNResponse<ContactAddressResponse>> {
    const url = APIConstant.company.getUserContactAddress;
    const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(HeaderTypes.SSO_TOKEN);
    return PTVNClient.get<PTVNResponse<ContactAddressResponse>>(url, options).pipe(
      map((m) => m.data)
    );
  }

  public static updateUserBranch(request: UserBranchDetailRequest): Observable<any> {
    const url = APIConstant.company.updateUserBranch;
    let requestBody: UserBranchDetailRequest;
    if (request.jobTitleId === JobTitleId.OTHER) {
      requestBody = {
        jobTitleId: request.jobTitleId,
        otherJobTitleName: request.otherJobTitleName,
        orgId: request.orgId,
        department: request.department
      }
    } else {
      requestBody = {
        jobTitleId: request.jobTitleId,
        orgId: request.orgId,
        department: request.department
      }
    }
    const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(HeaderTypes.SSO_TOKEN);
    return PTVNClient.post<any>(url, requestBody, options).pipe(
      map((m) => m.data)
    );
  }

  public static updateContactAddress(request: ContactAddressRequest): Observable<PTVNResponse<ContactAddressResponse>> {
    const url = APIConstant.company.updateContactAddress;
    const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(HeaderTypes.SSO_TOKEN);
    return PTVNClient.put<PTVNResponse<ContactAddressResponse>>(url, request, options).pipe(
      map((m) => m.data)
    );
  }

  public static getUserAvailableService(): Observable<PTVNResponse<AvailableServiceResponse>> {
    const url = APIConstant.company.getAvailableService;
    const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(HeaderTypes.SSO_TOKEN);
    return PTVNClient.get<PTVNResponse<AvailableServiceResponse>>(url, options).pipe(
      map((m) => m.data)
    );
  }

  public static getMasterLocale(): Observable<PTVNResponse<MasterLocaleResponse[]>> {
    const url = APIConstant.locale.getMasterLocale;
    const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(HeaderTypes.SSO_TOKEN);
    return PTVNClient.get<PTVNResponse<MasterLocaleResponse[]>>(url, options).pipe(
      map((m) => m.data)
    );
  }

  public static getMasterTimeZone(): Observable<PTVNResponse<MasterTimeZoneResponse[]>> {
    const url = APIConstant.locale.getMasterTimeZone;
    const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(HeaderTypes.SSO_TOKEN);
    return PTVNClient.get<PTVNResponse<MasterTimeZoneResponse[]>>(url, options).pipe(
      map((m) => m.data)
    );
  }

  public static updatePreference(request: UpdatePreferenceRequest) {
    const url = APIConstant.locale.updatePreference
    const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(
      HeaderTypes.SSO_TOKEN
    );
    return PTVNClient.post<PTVNResponse<VoidFunction>>(
      url,
      request,
      options
    );
  }

  public static getUserSWWInformation(): Observable<PTVNResponse<UserSWWInformationResponse>> {
    const url = APIConstant.company.getUserSWWInformation;
    const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(HeaderTypes.SSO_TOKEN);
    return PTVNClient.get<PTVNResponse<UserSWWInformationResponse>>(url, options).pipe(
      map((m) => m.data)
    );
  }

  public static getMasterFieldApprove(identityTypeID: number): Observable<PTVNResponse<MasterFieldApproveResponse[]>> {
    const url = APIConstant.company.getMasterFieldApprove.replace('{identityTypeID}', identityTypeID.toString());
    const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(HeaderTypes.SSO_TOKEN);
    return PTVNClient.get<PTVNResponse<MasterFieldApproveResponse[]>>(url, options).pipe(map((m) => m.data)
    );
  }

  public static getAutoAcceptPOShowNotification(branchId: number): Observable<PTVNResponse<AutoAcceptPOShowNotificationResponse>> {
    const url = APIConstant.company.getAutoAcceptPOShowNotification.replace('{branchId}', branchId.toString());
    const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(HeaderTypes.SSO_TOKEN);
    return PTVNClient.get<PTVNResponse<AutoAcceptPOShowNotificationResponse>>(url, options).pipe(
      map((m) => m.data)
    );
  }

  public static userMassFirstTimeAccessToSupplierConnect(branchId: number): Observable<PTVNResponse<FirstAccessRedirectResponse>> {
    const url = APIConstant.company.userMassFirstTimeAccessToSupplierConnect.replace("{branchId}", branchId.toString());
    const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(HeaderTypes.SSO_TOKEN);
    return PTVNClient.get<PTVNResponse<FirstAccessRedirectResponse>>(url, options).pipe(
      map((m) => m.data)
    );
  }

  public static saveAutoAcceptPONotificationDate(branchId: number): Observable<PTVNResponse<any>> {
    const url = APIConstant.company.saveAutoAcceptPOShowNotification;
    const request = {
      branchId: branchId
    }
    const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(HeaderTypes.SSO_TOKEN);
    return PTVNClient.post<PTVNResponse<any>>(url, request, options).pipe(
      map((m) => m.data)
    );
  }

  public static getPopupMessage(id: number): Observable<PTVNResponse<PopupMessageResponse>> {
    const url = APIConstant.company.popupMessage.replace("{id}", id.toString());
    const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(HeaderTypes.SSO_TOKEN);
    return PTVNClient.get<PTVNResponse<PopupMessageResponse>>(url, options).pipe(
      map((m) => m.data)
    );
  }

  public static getAutoAcceptPODetailData(branchId: number): Observable<PTVNResponse<AutoAcceptPODetailResponse>> {
    const url = APIConstant.company.getAutoAcceptPODetail.replace('{branchId}', branchId.toString());
    const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(HeaderTypes.SSO_TOKEN);
    return PTVNClient.get<PTVNResponse<AutoAcceptPODetailResponse>>(url, options).pipe(
      map((m) => m.data)
    );
  }

  public static upsertPurchaser(branchId: number, autoAcceptPOPurchaser: AutoAcceptPOPurchaser) {
    const url = APIConstant.company.upsertPurchaser;
    const request = {
      branchID: branchId,
      autoAcceptPOPurchaserID: autoAcceptPOPurchaser.autoAcceptPOPurchaserID,
      purchaserName: autoAcceptPOPurchaser.purchaserName,
      email: autoAcceptPOPurchaser.email
    }
    const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(HeaderTypes.SSO_TOKEN);
    return PTVNClient.post<PTVNResponse<VoidFunction>>(url, request, options);
  }

  public static deletePurchaser(branchId: number, autoAcceptPOId: number, autoAcceptPOPurchaserId: number) {
    const url = APIConstant.company.deletePurchaser;
    const request = {
      branchID: branchId,
      autoAcceptPOID: autoAcceptPOId,
      autoAcceptPOPurchaserID: autoAcceptPOPurchaserId
    }
    const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(HeaderTypes.SSO_TOKEN);
    return PTVNClient.post<PTVNResponse<VoidFunction>>(url, request, options);
  }

  public static autoAcceptPODownloadTemplate(branchId: number, language: 'th' | 'en'): Observable<File> {
    const url = APIConstant.company.autoAcceptPODownloadTemplate;
    const request = {
      branchId: branchId,
      language: language
    }
    let options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(HeaderTypes.SSO_TOKEN);
    options = { ...options, responseType: 'blob' };
    return PTVNClient.post<File>(url, request, options).pipe(
      map((m) => m.data)
    );
  }

  public static autoAcceptPOActive(autoAcceptPOID: number, isActive: boolean, remark?: string): Observable<PTVNResponse<any>> {
    const url = APIConstant.company.autoAcceptPOActive;
    let request = {
      autoAcceptPOID: autoAcceptPOID,
      isActive: isActive
    } as AutoAcceptPOActiveRequest

    if (remark) {
      request = { ...request, remark: remark }
    }

    const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(HeaderTypes.SSO_TOKEN);
    return PTVNClient.put<PTVNResponse<any>>(url, request, options).pipe(
      map((m) => m.data)
    );
  }

  public static updateAutoAcceptPOStatus(autoAcceptPOID: number, requestAutoAcceptPOId: number, status: AutoAcceptPOStatusType, remark?: string): Observable<PTVNResponse<any>> {
    const url = APIConstant.company.updateAutoAcceptPOStatus;
    let request = {
      autoAcceptPOID: autoAcceptPOID,
      status: status,
      requestAutoAcceptPOId
    } as UpdateAutoAcceptPOStatusRequest

    if (remark) {
      request = { ...request, remark: remark }
    }

    const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(HeaderTypes.SSO_TOKEN);
    return PTVNClient.put<PTVNResponse<any>>(url, request, options).pipe(
      map((m) => m.data)
    );
  }

  public static submitAutoAcceptRequest(branchId: number): Observable<PTVNResponse<any>> {
    const url = APIConstant.company.submitAutoAcceptPORequest;
    let request = {
      branchID: branchId
    }

    const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(HeaderTypes.SSO_TOKEN);
    return PTVNClient.post<PTVNResponse<any>>(url, request, options).pipe(
      map((m) => m.data)
    );
  }

  public static getAutoAcceptPOHistory(autoAcceptPOID: number): Observable<PTVNResponse<AutoAcceptPOHistoryResponse[]>> {
    const url = APIConstant.company.getAutoAcceptPOHistory.replace('{autoAcceptPOID}', autoAcceptPOID.toString());
    const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(HeaderTypes.SSO_TOKEN);
    return PTVNClient.get<PTVNResponse<AutoAcceptPOHistoryResponse[]>>(url, options).pipe(
      map((m) => m.data)
    );
  }

  public static checkShowAutoAcceptPOMenu(branchID: number): Observable<PTVNResponse<CheckShowAutoAcceptPOMenu>> {
    const url = APIConstant.company.checkShowAutoAcceptPOMenu.replace('{branchId}', branchID.toString());
    const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(HeaderTypes.SSO_TOKEN);
    return PTVNClient.get<PTVNResponse<CheckShowAutoAcceptPOMenu>>(url, options).pipe(
      map((m) => m.data)
    );
  }

  public static getMasterPaymentTerm(): Observable<MasterPaymentTerm[]> {
    const url = APIConstant.company.masterPaymentTerm;
    const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(HeaderTypes.SSO_TOKEN);
    return PTVNClient.get<PTVNResponse<MasterPaymentTerm[]>>(url, options).pipe(
      map((m) => m.data.data)
    );
  }

  public static getUserAdminPO(branchID: number): Observable<UserAdminPOListResponse> {
    const url = APIConstant.company.getUserAdminPO.replace('{branchId}', branchID.toString());;
    const options: AxiosRequestConfig = HttpClientHelper.getRequestOptions(HeaderTypes.SSO_TOKEN);
    return PTVNClient.get<PTVNResponse<UserAdminPOListResponse>>(url, options).pipe(
      map((m) => m.data.data)
    );
  }
}
